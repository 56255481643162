import { BaseHttpGateway } from '@/gateway/base/BaseHttpGateway'

export class AccountGateway extends BaseHttpGateway {
  async getChildrenAccount () {
    const res = await this.httpClient.get('user/childs')

    return res.data
  }

  async getAccountById (id) {
    const res = await this.httpClient.get(`users/${id}`)

    return res.data
  }

  async getAllUsers () {
    const res = await this.httpClient.get('users')

    return res.data
  }

  async getAllAvailableUsers (userId) {
    const res = await this.httpClient.get(`users/${userId}/foraddition`)

    return res.data
  }


  async addUserToAccount(masterUserId,slaveUserId){
    const res = await this.httpClient.post(`users/${masterUserId}/add/${slaveUserId}`)

    return res.data
  }

  async removeUserFromAccount(masterUserId,slaveUserId){
    const res = await this.httpClient.delete(`users/${masterUserId}/add/${slaveUserId}`)

    return res.data
  }

  async changeProfile(userId,data){
    const res = await this.httpClient.put(`users/${userId}`,data)

    return res.status
  }

  async changePayments(userId, data) {
    const res = await this.httpClient.put(`users/${userId}/payment`, data)
    return res
  }

  async deleteProfile(userId){
    const res = await this.httpClient.delete(`users/${userId}`)

    return res.data
  }
}
