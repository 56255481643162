import {BaseHttpGateway} from "@/gateway/base/BaseHttpGateway";

export class RegisterGateway extends BaseHttpGateway{

    async registerUser(registerData){
        try{
            const res = await this.httpClient.post(`register/user`,registerData);

            return res.status
        } catch (e){
            return e.status
        }
    }

    async registerMultiUser(registerData){
        const res = await this.httpClient.post(`register/multiuser`,registerData);

        return res.status
    }
}
