import {BaseHttpGateway} from "@/gateway/base/BaseHttpGateway";

export class CameraGateway extends BaseHttpGateway{

    async createCamera(userId,cameraData){
        const res = await this.httpClient.post(`users/${userId}/camera`,cameraData);

        return res.status;
    }


    async deleteCamera(userId,cameraId){
        const res = await this.httpClient.delete(`users/${userId}/camera/${cameraId}`)

        return res.status
    }
}
