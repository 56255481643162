import Vue from 'vue'
import Vuex from 'vuex'
import {AuthGateway} from "@/gateway/AuthGateway";
import {AccountGateway} from "@/gateway/AccountGateway";
import {RegisterGateway} from "@/gateway/RegisterGateway";
import {CameraGateway} from "@/gateway/CameraGateway";

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    authGateway: new AuthGateway(),
    accountGateway: new AccountGateway(),
    registerGateway: new RegisterGateway(),
    cameraGateway: new CameraGateway(),
    users: [],
    selectedUser:{},
    availableUsers: [],
    account: {}
  },
  getters:{
    ACCOUNT: (state) => state.account,
    USERS: (state) => state.users,
    SELECTED_USER: (state) => state.selectedUser,
    AVAILABLE_USERS: (state) => state.availableUsers
  },
  mutations: {
    SET_ACCOUNT: (state,account) => {
      state.account = {...account}
    },
    SET_USERS: (state,users) => {
      state.users = [...users]
    },
    SET_SELECTED_USER: (state,account) => {
      state.selectedUser = {...account}
    },
    SET_AVAILABLE_USERS: (state,users) => {
      state.availableUsers = [...users]
    },
  },
  actions: {
    AUTH: async ({ state }, { login, password }) => {
      return await state.authGateway.auth(login, password)
    },

    GET_ALL_USERS: async ({state,commit}) => {
      const users = await state.accountGateway.getAllUsers();

      commit("SET_USERS",users);
    },

    GET_AVAILABLE_USERS: async ({state,commit},{userId}) => {
      const users = await state.accountGateway.getAllAvailableUsers(userId);

      commit("SET_AVAILABLE_USERS",users);
    },

    GET_SELECTED_USER: async ({state,commit},{userId}) => {
      const user = await state.accountGateway.getAccountById(userId);

      commit("SET_SELECTED_USER",user);
    },


    REGISTER_USER: async ({state}, {registerData}) => {
      return await state.registerGateway.registerUser(registerData)
    },

    REGISTER_MULTI_USER: async ({state}, {registerData}) => {
      return await state.registerGateway.registerMultiUser(registerData)
    },


    CREATE_CAMERA: async ({state}, {userId,cameraData}) => {
      return await state.cameraGateway.createCamera(userId,cameraData);
    },

    ADD_USER_TO_ACCOUNT: async ({state}, {masterUserId,slaveUserId}) => {
      return await state.accountGateway.addUserToAccount(masterUserId,slaveUserId);
    },

    REMOVE_USER_FROM_ACCOUNT: async ({state}, {masterUserId,slaveUserId}) => {
      return await state.accountGateway.removeUserFromAccount(masterUserId,slaveUserId);
    },

    DELETE_CAMERA: async ({state},{userId,camera}) =>{
      return await state.cameraGateway.deleteCamera(userId,camera.id)
    },
    CHANGE_PROFILE: async ({state}, {userId,data}) => {
      return await state.accountGateway.changeProfile(userId,data);
    },
    DELETE_PROFILE: async ({state}, {userId}) => {
      return await state.accountGateway.deleteProfile(userId);
    },
    CHANGE_PAYMENTS: async ({state, commit}, {userId, data}) => {
      const resp = await state.accountGateway.changePayments(userId, data)
      if (resp.status === 200) {
        commit('SET_SELECTED_USER', resp.data)
      }
      return state
    }
  },
  modules: {
  }
})
