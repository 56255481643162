import axios from 'axios'

export class HttpClient {
    static BASE_URL = 'https://apiv2.eyeinc.ru/';
    // static BASE_URL = 'https://test-apiv2.eyeinc.ru/';
    static API_VERSION = '';
    static httpClient = null;
    static isUpdate = false;

    static getHttpClient () {
      if (HttpClient.httpClient == null) {
        HttpClient.httpClient = this.make()
      }
      return HttpClient.httpClient
    }

    static make () {
      const httpClient = axios.create({
        baseURL: `${this.BASE_URL}/${this.API_VERSION}/`
      })

      httpClient.interceptors.request.use(this.requestInterceptor, function (error) {
        return Promise.reject(error)
      })

      httpClient.interceptors.response.use(this.responseInterceptor, this.authenticatorInterceptor)

      return httpClient
    }

    static requestInterceptor (config) {
      if (config.headers.common.Authorization == null) {
        config.headers.common.Authorization = `Bearer ${localStorage.getItem('accessToken')}`
      }

      if (config.headers['Content-Type'] == null) {
        config.headers.common['Content-Type'] = 'application/json'
      }
      return config
    }

    static authenticatorInterceptor (error) {
      if (error.response == null) throw error
      if (error.response.status === 401) {
        const refreshToken = localStorage.getItem('refreshToken')
        if (!HttpClient.isUpdate) {
          HttpClient.isUpdate = true

          return HttpClient.getHttpClient().put(`admin/auth/${refreshToken}`, null).then((response) => {
            if (response == null) {
            // document.location.href = 'auth'
            }

            if (response.status === 400) {
              document.location.href = '/auth'
            }

            const token = response.data

            localStorage.setItem('accessToken', token.accessToken)
            localStorage.setItem('refreshToken', token.refreshToken)

            error.config.headers.Authorization = `Bearer ${token.accessToken}`
            HttpClient.isUpdate = false
            return HttpClient.getHttpClient().request(error.config)
          })
        }
      } else {
        return error.response
      }
    }

    static responseInterceptor (response) {
      return response
    }
}
