import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'MainView',
    component: () => import("../views/Main/MainUsersListView")
  },
  {
    path: '/user/:id',
    name: 'UserView',
    component: () => import("../views/User/UserView")
  },
  {
    path: '/multiuser/:id',
    name: 'MultiUserView',
    component: () => import("../views/User/MultiAccountView")
  },
  {
    path: '/auth',
    name: 'Auth',
    component: () => import("../views/Auth/AuthView")
  },
  {
    path: '/register',
    name: 'Register',
    component: () => import("../views/Register/RegisterSelectView")
  },
  {
    path: '/register/user',
    name: 'RegisterUserView',
    component: () => import("../views/Register/RegisterUserView")
  },
  {
    path: '/register/multi',
    name: 'RegisterMultiAccountView',
    component: () => import("../views/Register/RegisterMultiAccountView")
  },

  {
    path: '/user/:id/camera/create',
    name: 'CreateCameraView',
    component: () => import("../views/User/CreateCameraView")
  },

  {
    path: '/user/:id/settings',
    name: 'ChangeUserDataView',
    component: () => import("../views/User/ChangeUserDataView")
  },
  {
    path: '/multiuser/:id/settings',
    name: 'ChangeMultiDataView',
    component: () => import("../views/User/ChangeMultiDataView")
  },
  {
    path: '/user/:id/archive',
    name: 'Archive',
    meta: {
      title: 'Архив'
    },
    component: () => import('../views/User/ArchiveView'),
    props: {
      camera: JSON.parse(localStorage.getItem('camera'))
    }
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
