import { BaseHttpGateway } from '@/gateway/base/BaseHttpGateway'
import {WrongLoginOrPasswordException} from "@/exception/WrongLoginOrPasswordException";

export class AuthGateway extends BaseHttpGateway {
  async auth (phone, password) {
    phone = phone.substr(0, 16).replace(/[^a-zA-Z0-9]/g, '')
    const res = await this.httpClient.post('admin/auth', {
      phone: phone,
      password: password
    })

    if (res.status !== 200) throw new WrongLoginOrPasswordException()

    return res.data
  }

  async getAccount () {
    const res = await this.httpClient.get('user')

    return res.data
  }
}
